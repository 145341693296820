import { Box, Grid } from '@mui/material'
import * as React from 'react'
import { useAppSelector } from '../../app/hooks'
import { selectTemplateById } from './templateSelector'
import style from './Template.module.css'
import moment from 'moment'
import { NavLink } from 'react-router-dom'

export interface ITemplateCardProps {
    templateId: string
}

export const TemplateCard: React.FC<ITemplateCardProps> = ({templateId}) => {
    const template = useAppSelector((store) => selectTemplateById(store, templateId));

    return <Box className={style.templateCard}>
        <Box display={'flex'} flexDirection={'column'}>
            <span className={style.templateCardName}>{template.name}</span>
            <Grid display={'flex'} justifyContent={'space-between'}>
                <span className={style.templateCardCreatedAt}>{moment(template.created_at).format('MMM D, YYYY')}</span>
                <span className={style.templateCardCreatedAt}>upd:{moment(template.updated_at).format('MMM D, YYYY')}</span>
            </Grid>
        </Box>
        <Box marginTop={'10px'} marginBottom={'10px'}>
            <span className={style.templateCardTemplate}>{template.template}</span>
        </Box>
        <Grid display={'flex'} justifyContent={'right'}>
            <NavLink
                className={style.exploreBtn}
                to={`/templates/${templateId}`}
            >
                Explore -&gt;
            </NavLink>
        </Grid>
    </Box>
}
