import {createAsyncThunk} from '@reduxjs/toolkit';
import {AxiosError} from 'axios';
import {generate} from "./PromptAPI";
import {setStatus} from "./promptSlice";

type AxiosErrorType = AxiosError<{ message?: string }>;
const defaultError = 'Something went wrong. Please try again later.';

interface IRejectedValue {
    message: string;
    time: string;
}


export const generateAsync = createAsyncThunk<string,
    void,
    {
        rejectValue: IRejectedValue;
    }>('prompt/generate', async (data, thunkApi) => {
    try {
        thunkApi.dispatch(setStatus('loading'))
        const {prompt} = (thunkApi.getState() as any).prompt;
        const {conditions} = (thunkApi.getState() as any).conditions;
        const response = await generate(prompt, conditions.map((item: any) => item.condition));
        thunkApi.dispatch(setStatus('idle'))
        return response;
    } catch (err) {
        const {response} = err as AxiosErrorType;
        thunkApi.dispatch(setStatus('idle'))
        return thunkApi.rejectWithValue({
            message: response?.data.message || defaultError,
            time: new Date().toISOString(),
        });
    }
});

