import { createSlice } from '@reduxjs/toolkit'
import {
    addTemplateAsync,
    deleteTemplateAsync,
    executeTemplatesAsync,
    getTemplatesAsync, updateTemplateAsync,
} from './features/templates/templateThunkCreator'


export interface ITemplateState {
    appStatus: 'idle' | 'loading' | 'error'
}

const initialState: ITemplateState = {
    appStatus: 'idle',
}

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getTemplatesAsync.pending, (state, action) => {
            state.appStatus = 'loading'
        })
        builder.addCase(getTemplatesAsync.fulfilled, (state, action) => {
            state.appStatus = 'idle'
        })
        builder.addCase(getTemplatesAsync.rejected, (state, action) => {
            state.appStatus = 'error'
        })


        builder.addCase(executeTemplatesAsync.pending, (state) => {
            state.appStatus = 'loading'
        })
        builder.addCase(executeTemplatesAsync.fulfilled, (state, action) => {
            state.appStatus = 'idle'
        })
        builder.addCase(executeTemplatesAsync.rejected, (state, action) => {
            state.appStatus = 'error'
        })


        builder.addCase(addTemplateAsync.pending, (state) => {
            state.appStatus = 'loading'
        })
        builder.addCase(addTemplateAsync.fulfilled, (state, action) => {
            state.appStatus = 'idle'
        })
        builder.addCase(addTemplateAsync.rejected, (state, action) => {
            state.appStatus = 'error'
        })

        builder.addCase(deleteTemplateAsync.pending, (state) => {
            state.appStatus = 'loading'
        })
        builder.addCase(deleteTemplateAsync.fulfilled, (state, action) => {
            state.appStatus = 'idle'
        })
        builder.addCase(deleteTemplateAsync.rejected, (state, action) => {
            state.appStatus = 'error'
        })

        builder.addCase(updateTemplateAsync.pending, (state) => {
            state.appStatus = 'loading'
        })
        builder.addCase(updateTemplateAsync.fulfilled, (state, action) => {
            state.appStatus = 'idle'
        })
        builder.addCase(updateTemplateAsync.rejected, (state, action) => {
            state.appStatus = 'error'
        })


    },
})

export const {} = appSlice.actions

export default appSlice.reducer
