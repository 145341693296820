import axios from 'axios';

const API_HOST = 'https://api.kelly.alekseichik.dev/conversation'

export const generate = async (prompt: string, conditions: string[]): Promise<string> => {
    const response = await axios.post<string>(API_HOST, {
        prompt,
        conditions
    })
    return response.data;
}
