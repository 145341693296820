import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'
import {
    getTemplatesRequest,
    executeTemplate,
    addTemplatesRequest,
    deleteTemplateRequest,
    updateTemplateRequest,
} from './templateAPI'
import { ITemplate } from './templateSlice'

type AxiosErrorType = AxiosError<{ message?: string }>;
const defaultError = 'Something went wrong. Please try again later.'

interface IRejectedValue {
    message: string;
    time: string;
}


export const getTemplatesAsync = createAsyncThunk<ITemplate[],
    void,
    {
        rejectValue: IRejectedValue;
    }>('template/get_templates', async (data, thunkApi) => {
    try {
        return await getTemplatesRequest()
    } catch (err) {
        const { response } = err as AxiosErrorType
        return thunkApi.rejectWithValue({
            message: response?.data.message || defaultError,
            time: new Date().toISOString(),
        })
    }
})

export const addTemplateAsync = createAsyncThunk<ITemplate,
    {
        name: string,
        template: string
    },
    {
        rejectValue: IRejectedValue;
    }>('template/add_template', async (data, thunkApi) => {
    try {
        return await addTemplatesRequest(data)
    } catch (err) {
        const { response } = err as AxiosErrorType
        return thunkApi.rejectWithValue({
            message: response?.data.message || defaultError,
            time: new Date().toISOString(),
        })
    }
})

export const deleteTemplateAsync = createAsyncThunk<ITemplate,
    string,
    {
        rejectValue: IRejectedValue;
    }>('template/delete_template', async (data, thunkApi) => {
    try {
        return await deleteTemplateRequest(data)
    } catch (err) {
        const { response } = err as AxiosErrorType
        return thunkApi.rejectWithValue({
            message: response?.data.message || defaultError,
            time: new Date().toISOString(),
        })
    }
})

export const updateTemplateAsync = createAsyncThunk<ITemplate,
    {
        templateId: string,
        name?: string,
        template?: string
    },
    {
        rejectValue: IRejectedValue;
    }>('template/update_template', async (data, thunkApi) => {
    try {
        const { templateId, template, name } = data
        return await updateTemplateRequest(templateId, {
            template, name,
        })
    } catch (err) {
        const { response } = err as AxiosErrorType
        return thunkApi.rejectWithValue({
            message: response?.data.message || defaultError,
            time: new Date().toISOString(),
        })
    }
})


export const executeTemplatesAsync = createAsyncThunk<{ response: string[], templateId: string },
    {
        templateId: string,
        prompt?: string
    },
    {
        rejectValue: IRejectedValue;
    }>('template/execute_templates', async (data, thunkApi) => {
    try {
        const { templateId, prompt } = data
        const response = await executeTemplate(templateId, prompt)
        return {
            response,
            templateId,
        }
    } catch (err) {
        const { response } = err as AxiosErrorType
        return thunkApi.rejectWithValue({
            message: response?.data.message || defaultError,
            time: new Date().toISOString(),
        })
    }
})



