import axios from 'axios'
import { ITemplate } from './templateSlice'

const TEMPLATES_API_HOST = 'https://api.kelly.alekseichik.dev'

export const getTemplatesRequest = async (): Promise<ITemplate[]> => {
    const response = await axios.get<ITemplate[]>(`${TEMPLATES_API_HOST}/templates`)
    return response.data
}

export const executeTemplate = async (
    templateId: string,
    prompt?: string,
): Promise<string[]> => {
    const response = await axios.post<string[]>(`${TEMPLATES_API_HOST}/gpt/${templateId}`, { prompt })
    return response.data
}


export const addTemplatesRequest = async (body: {
    name: string,
    template: string,
}): Promise<ITemplate> => {
    const response = await axios.post<ITemplate>(`${TEMPLATES_API_HOST}/templates`, body)
    return response.data
}

export const deleteTemplateRequest = async (templateId: string): Promise<ITemplate> => {
    const response = await axios.delete<ITemplate>(`${TEMPLATES_API_HOST}/templates/${templateId}`)
    return response.data
}

export const updateTemplateRequest = async (templateId: string, body: {
    template?: string;
    name?: string;
}): Promise<ITemplate> => {
    const response = await axios.put<ITemplate>(`${TEMPLATES_API_HOST}/templates/${templateId}`, body)
    return response.data

}

