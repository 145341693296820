import * as React from 'react'
import { useEffect, useState } from 'react'
import { Grid, TextareaAutosize } from '@mui/material'
import { useParams } from 'react-router-dom'
import style from './Template.module.css'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { selectTemplateById, selectTemplateHistory } from './templateSelector'
import { deleteTemplateAsync, executeTemplatesAsync, updateTemplateAsync } from './templateThunkCreator'


export interface ITemplateProps {
}

export const Template: React.FC<ITemplateProps> = () => {
    const params = useParams()
    const dispatch = useAppDispatch()
    const templateId = params.id as string

    const template = useAppSelector((state) => selectTemplateById(state, templateId))
    const history = useAppSelector((state) => selectTemplateHistory(state, templateId))
    const [templateText, setTemplateText] = useState<string | undefined>(template?.template)
    const [promptText, setPromptText] = useState<string | undefined>(undefined)
    const [updateMode, setUpdateMode] = useState<boolean>(false)
    const [templateName, setTemplateName] = useState<string | undefined>(template?.name)

    const onChangeTemplate = (event: any) => setTemplateText(event.target.value)
    const onChangePrompt = (event: any) => setPromptText(event.target.value)
    const onChangeTemplateName = (event: any) => setTemplateName(event.target.value)

    const onDeleteButtonClick = () => {
        dispatch(deleteTemplateAsync(templateId))
    }

    useEffect(() => {
        setTemplateText(template?.template)
        setUpdateMode(false)
        setTemplateName(template?.name)
        setPromptText('')
    }, [template])


    useEffect(() => {
        if (updateMode) {
            setTemplateText(template?.template)
            setTemplateName(template?.name)
        }
    }, [updateMode])

    if (!template) {
        return <Grid container display={'flex'} flexDirection={'column'}>
            <Grid item>
                <span className={style.templateCardContainerName}>Loading ... </span>
            </Grid>
        </Grid>
    }

    const executeTemplate = () => dispatch(executeTemplatesAsync({
        templateId,
        prompt: promptText,
    }))

    const onClickSaveButton = () => {
        dispatch(updateTemplateAsync({
            templateId,
            template: templateText,
            name: templateName,
        }))
        setUpdateMode(false)
    }

    return <Grid container display={'flex'} flexDirection={'column'}>
        <Grid item>
            {
                updateMode ?
                    <input value={templateName} onChange={onChangeTemplateName}
                           className={style.templateCardContainerInputName} placeholder={'Template Name'} /> :
                    <span className={style.templateCardContainerName}>{template.name}</span>
            }
        </Grid>
        <Grid display={'flex'} flexDirection={'column'} container>
            <Grid item>
                <span className={style.templateCardInputLabel}>Prompt:</span>
            </Grid>
            <Grid item>
                <TextareaAutosize onChange={onChangePrompt} value={promptText} className={style.templateCardInput} />
            </Grid>
        </Grid>
        <Grid display={'flex'} flexDirection={'column'} container>
            <Grid item>
                <span className={style.templateCardInputLabel}>Template:</span>
            </Grid>
            <Grid item>
                {
                    updateMode ?
                        <TextareaAutosize onChange={onChangeTemplate} value={templateText}
                                          className={style.templateCardInput} />
                        :
                        <TextareaAutosize value={template.template}
                                          className={style.templateCardTemplateView} />

                }
            </Grid>
        </Grid>
        <Grid display={'flex'} justifyContent={'space-between'}>
            <div>
                <button
                    onClick={onDeleteButtonClick}
                    className={style.templateCardUpdateDeleteBtn}
                >
                    Delete
                </button>
                <button
                    onClick={() => setUpdateMode(!updateMode)}
                    className={style.templateCardUpdateDeleteBtn}
                >
                    {updateMode ? 'Cancel update' : 'Update'}
                </button>

            </div>
            {
                updateMode ?
                    <button onClick={onClickSaveButton}
                            disabled={!templateText || !templateName}
                            className={style.templateCardExecuteBtn}>
                        Save
                    </button>
                    :
                    <button onClick={executeTemplate}
                            className={style.templateCardExecuteBtn}>
                        Execute -&gt;
                    </button>
            }
        </Grid>
        <Grid display={'flex'} flexDirection={'column'}>
            <Grid item>
                <span className={style.templateCardInputLabel}>History:</span>
            </Grid>
            <Grid container display={'flex'} flexDirection={'column-reverse'}>
                {
                    history.map(item => {
                        return <TextareaAutosize value={item.response}
                                                 className={style.templateCardTemplateView} />

                    })
                }
            </Grid>
        </Grid>

    </Grid>
}
