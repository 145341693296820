import * as React from 'react'
import { useState } from 'react'
import { Grid, TextareaAutosize } from '@mui/material'
import style from './Template.module.css'
import { useAppDispatch } from '../../app/hooks'
import { addTemplateAsync } from './templateThunkCreator'


export interface ITemplateProps {
}

export const NewTemplate: React.FC<ITemplateProps> = () => {

    const [templateText, setTemplateText] = useState<string | undefined>(undefined)
    const [templateName, setTemplateName] = useState<string | undefined>(undefined)

    const onChangeTemplate = (event: any) => setTemplateText(event.target.value)
    const onChangeTemplateName = (event: any) => setTemplateName(event.target.value)

    const dispatch = useAppDispatch()

    const onSaveClick = () => {
        if (templateName && templateText) {
            dispatch(addTemplateAsync({
                name: templateName,
                template: templateText,
            }))

        }
    }

    return <Grid container display={'flex'} flexDirection={'column'}>
        <Grid item>
            <input value={templateName} onChange={onChangeTemplateName}
                   className={style.templateCardContainerInputName} placeholder={'Template Name'} />
        </Grid>
        <Grid display={'flex'} flexDirection={'column'} container>
            <Grid item>
                <span className={style.templateCardInputLabel}>Template:</span>
            </Grid>
            <Grid item>
                <TextareaAutosize onChange={onChangeTemplate} value={templateText}
                                  className={style.templateCardInput} />
            </Grid>
        </Grid>
        <Grid display={'flex'} justifyContent={'right'}>
            <button
                onClick={onSaveClick}
                disabled={!(!!templateText && !!templateName)}
                className={style.templateCardExecuteBtn}>
                Save
            </button>
        </Grid>

    </Grid>
}
