import { createSlice } from '@reduxjs/toolkit'
import {
    addTemplateAsync,
    deleteTemplateAsync,
    executeTemplatesAsync,
    getTemplatesAsync,
    updateTemplateAsync,
} from './templateThunkCreator'

export interface ITemplate {
    id: string;
    created_at: Date,
    updated_at: Date,
    name: string;
    template: string;
}

export interface ITemplateState {
    templates: {
        allIds: string[],
        byId: Record<string, ITemplate>
    },
    history: Record<string, Array<{
        response: string[],
        executedAt: number,
    }>>
    newTemplate: Partial<ITemplate>
}

const initialState: ITemplateState = {
    templates: {
        allIds: [],
        byId: {},
    },
    history: {},
    newTemplate: {},
}

export const templateSlice = createSlice({
    name: 'template',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getTemplatesAsync.fulfilled, (state, action) => {
            const { payload: templates } = action
            state.templates.byId = {}
            state.templates.allIds = []
            templates.forEach((item) => {
                state.templates.byId[item.id] = item
                state.templates.allIds.push(item.id)
            })
        })

        builder.addCase(executeTemplatesAsync.fulfilled, (state, action) => {
            const { response, templateId } = action.payload

            if (!state.history[templateId]) {
                state.history[templateId] = []
            }

            state.history[templateId].push({
                response,
                executedAt: Date.now(),
            })
        })

        builder.addCase(addTemplateAsync.fulfilled, (state, action) => {
            const template = action.payload
            state.templates.allIds.push(template.id)
            state.templates.byId[template.id] = template
        })

        builder.addCase(deleteTemplateAsync.fulfilled, (state, action) => {
            const { id } = action.payload
            delete state.templates.byId[id]
            state.templates.allIds.splice(state.templates.allIds.indexOf(id), 1)
        })

        builder.addCase(updateTemplateAsync.fulfilled, (state, action) => {
            const template = action.payload
            state.templates.byId[template.id] = template
        })
    },
})

export const {} = templateSlice.actions

export default templateSlice.reducer
