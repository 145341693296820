import {createSlice, PayloadAction} from "@reduxjs/toolkit";


export interface ConditionsState {
    conditions: Array<{
        condition: string;
        key: number;
    }>
}

const initialState: ConditionsState = {
    conditions: [],
};

export const conditionsSlice = createSlice({
    name: 'conditions',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        addCondition: (state, action: PayloadAction<string>) => {
            state.conditions.push({
                condition: action.payload,
                key: Date.now(),
            })
        },
        deleteCondition: (state, action: PayloadAction<number>) => {
            state.conditions = state.conditions.filter((chip) => chip.key !== action.payload)
        }
    },
});

export const {addCondition, deleteCondition} = conditionsSlice.actions;

export default conditionsSlice.reducer;


