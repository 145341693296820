import React from 'react'
import './App.css'
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom'
import { TemplateApp } from './features/templates/TemplateApp'
import { Container } from '@mui/material'
import { useAppSelector } from './app/hooks'
import { selectAppStatus } from './appSelector'


function Layout() {

    const appStatus = useAppSelector(selectAppStatus)
    return (
        <>
            {
                appStatus === 'loading' && (
                    <div className={'load-rainbow'}>
                        <div />
                    </div>
                )
            }
            <Container>
                <Outlet />
            </Container>
        </>
    )
}


function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path='/' element={<Layout />}>
                    <Route index path='templates/*' element={<TemplateApp />} />
                </Route>
            </Routes>
        </BrowserRouter>
    )

}

export default App
