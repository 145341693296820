import * as React from 'react'
import { Grid } from '@mui/material'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { getTemplatesAsync } from './templateThunkCreator'
import { selectAllTemplates } from './templateSelector'
import { TemplateCard } from './TemplateCard'
import style from './Template.module.css'
import { NavLink, Outlet, Route, Routes } from 'react-router-dom'
import { Template } from './Template'
import { NewTemplate } from './NewTemplate'

const Layout = () => {
    const templates = useAppSelector(selectAllTemplates)
    return <Grid marginTop={'20px'} container columnGap={5}>
        <Grid item xs={4}>

            <Grid marginBottom={'40px'} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                          <span className={style.templateLabel}>
                Templates
            </span>
                <NavLink
                    className={style.exploreBtn}
                    to={`/templates/new`}
                >
                    New+
                </NavLink>

            </Grid>

            <Grid display={'flex'} justifyContent={'center'} flexDirection={'column'} rowGap={2}>
                {
                    templates.map((templateId) => (
                        <TemplateCard key={templateId} templateId={templateId} />
                    ))
                }
            </Grid>

        </Grid>
        <Grid item xs={7}>
            <Outlet />
        </Grid>
    </Grid>

}


export const TemplateApp: React.FC = () => {

    const dispatch = useAppDispatch()

    React.useEffect(() => {
        dispatch(getTemplatesAsync())
    }, [])
    return (<Routes>
            <Route element={<Layout />}>
                <Route path='new' element={<NewTemplate />} />
                <Route path=':id' element={<Template />} />
                <Route index path='*' element={<></>} />
            </Route>

        </Routes>
    )
}
