import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit'
import counterReducer from '../features/counter/counterSlice'
import conditionsReducer from '../features/conditions/conditionsSlice'
import promptReducer from '../features/prompt/promptSlice'
import templateReducer from '../features/templates/templateSlice'
import appReducer from '../appSlice'

export const store = configureStore({
    reducer: {
        app: appReducer,
        counter: counterReducer,
        conditions: conditionsReducer,
        prompt: promptReducer,
        template: templateReducer,
    },
})

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType,
    RootState,
    unknown,
    Action<string>>;
