import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {generateAsync} from "./PromptThunkCreators";


type PromptStatus = 'loading' | 'idle';

export interface PromptState {
    prompt?: string,
    function?: string
    status: PromptStatus
}

const initialState: PromptState = {
    status: "idle"
};

export const promptSlice = createSlice({
    name: 'prompt',
    initialState,
    reducers: {
        setPrompt: (state, action: PayloadAction<string>) => {
            state.prompt = action.payload;
        },
        setFunction: (state, action: PayloadAction<string>) => {
            state.function = action.payload;
        },
        setStatus: (state, action: PayloadAction<PromptStatus>) => {
            state.status = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(generateAsync.fulfilled, (state, action) => {
            state.function = action.payload;
        });
        builder.addCase(generateAsync.rejected, (state) => {
        });
    }
});

export const {setPrompt, setFunction, setStatus} = promptSlice.actions;

export default promptSlice.reducer;
